import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import withTranslation from 'next-translate/withTranslation'
import { compose } from 'recompose'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import { prepareFilterValues } from 'SRC/modules/ads/filter/functions'
import { Links } from 'SRC/modules/common/components/Pagination'
import { getInfo } from 'SRC/modules/users/detail/selectors'
import { getPaginationParams } from '../../selectors'

import translations from '../../../../../../public/data/translations.json'

export class Search extends React.PureComponent {
  getPageCount = () => Math.ceil((this.props.paginationParams.totalCount) / this.props.paginationParams.countPerPage)

  getBaseUrl = () => {
    const { currentSubCategory, currentGroup, isUserSearch, user, i18n } = this.props

    const locale = i18n?.lang?.toUpperCase() || 'ME'

    const currentSubCategorySeo = currentSubCategory
      ? currentSubCategory.seo
      : currentGroup
        ? currentGroup.seo
        : ``

    let asStr = ''
    let category = ''

    if (currentSubCategorySeo) {
      category = `/${currentSubCategorySeo}/`
    }

    if (isUserSearch) {
      if (user) asStr = `/${user.type.seo}/${user.seo || user.username}/${category}${translations.pretraga[locale]}`
    } else {
      asStr = `/${category}${translations.pretraga[locale]}`
    }

    return {
      href: {
        pathname: `/search`,
        query: {
          category: currentSubCategorySeo,
          username: user ? user.seo || user.username : '',
          userType: user ? user.type.seo : ''
        }
      },
      as: asStr
    }
  }

  getPrevUrl = () => ({
    href: {
      pathname: this.getBaseUrl().href.pathname,
      query: {
        ...this.getBaseUrl().href.query,
        ...prepareFilterValues(this.props.filterValues),
        formStyle: this.props.formStyle,
        pageNumber: Number(this.props.paginationParams.currentPage) - 1
      }
    },
    as: {
      pathname: this.getBaseUrl().as,
      query: {
        ...prepareFilterValues(this.props.filterValues),
        formStyle: this.props.formStyle,
        pageNumber: Number(this.props.paginationParams.currentPage) - 1
      }
    }
  })

  getNextUrl = () => ({
    href: {
      pathname: this.getBaseUrl().href.pathname,
      query: {
        ...this.getBaseUrl().href.query,
        ...prepareFilterValues(this.props.filterValues),
        formStyle: this.props.formStyle,
        pageNumber: Number(this.props.paginationParams.currentPage) + 1
      }
    },
    as: {
      pathname: this.getBaseUrl().as,
      query: {
        ...prepareFilterValues(this.props.filterValues),
        formStyle: this.props.formStyle,
        pageNumber: Number(this.props.paginationParams.currentPage) + 1
      }
    }
  })

  getPages = (pageCount = 1) => {
    const pages = []
    const baseUrl = this.getBaseUrl()
    const current = Number(this.props.paginationParams.currentPage)
    for (let i = 1; i <= pageCount; i++) {
      pages.push({
        number: i,
        isCurrent: i === current,
        href: {
          pathname: baseUrl.href.pathname,
          query: {
            ...prepareFilterValues(this.props.filterValues),
            ...baseUrl.href.query,
            formStyle: this.props.formStyle,
            pageNumber: i
          }
        },
        as: {
          pathname: baseUrl.as,
          query: {
            ...prepareFilterValues(this.props.filterValues),
            formStyle: this.props.formStyle,
            pageNumber: i
          }
        }
      })
    }
    return pages
  }

  render () {
    const pageCount = this.getPageCount()
    const pages = this.getPages(pageCount)
    const currentPage = Number(this.props.paginationParams.currentPage)
    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === pageCount
    const prevUrl = this.getPrevUrl()
    const nextUrl = this.getNextUrl()

    if (pageCount <= 0) return null

    return (
      <Links
        isFirstPage={isFirstPage}
        isLastPage={isLastPage}
        prevUrl={prevUrl}
        nextUrl={nextUrl}
        pages={pages}
      />
    )
  }
}

const mapStateToProps = state => ({
  paginationParams: getPaginationParams(state),
  currentSubCategory: getCurrentSubCategory(state),
  currentGroup: getCurrentGroup(state),
  user: getInfo(state)
})

Search.propTypes = {
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    countPerPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired
  }).isRequired,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    seo: PropTypes.string,
    type: PropTypes.shape({
      seo: PropTypes.string.isRequired
    })
  }),
  currentSubCategory: PropTypes.shape({
    seo: PropTypes.string.isRequired
  }),
  currentGroup: PropTypes.shape({
    seo: PropTypes.string.isRequired
  }),
  filterValues: PropTypes.object.isRequired,
  isUserSearch: PropTypes.bool
}

Search.defaultProps = {
  filterValues: {}
}

const enhance = compose(
  withTranslation,
  connect(mapStateToProps)
)

export default enhance(Search)
