import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'
import { getPages } from '../functions/getPages'

export const Numbers = ({ pages }) => {
  const links = getPages(pages)

  return links.map(({ number, href, as, isCurrent }) => (
    <li
      className={`ads-pagination__item ${isCurrent ? 'ads-pagination__item_current active' : ''}`}
      key={number}
      id={number}
    >
      {!isCurrent ? (
        <Link href={href} as={as}>
          <a className={'ads-pagination__item-link'}>{number}</a>
        </Link>
      ) : (
        <a href='javascript:void(0)' className={'ads-pagination__item-link'}>{number}</a>
      )}
    </li>
  ))
}

Numbers.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.any.isRequired,
    as: PropTypes.any.isRequired,
    number: PropTypes.number.isRequired,
    isCurrent: PropTypes.bool.isRequired
  })).isRequired
}
