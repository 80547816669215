import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getAds } from '../../../list/selectors'
import { getPaginationParams } from '../../selectors'
import { getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import { Links } from 'SRC/modules/common/components/Pagination'
import { adsTypes } from 'Core/constants'

export const Category = ({paginationParams, ads, currentSubcategory, currentGroup}) => {
  const pageCount = Math.ceil(
    (paginationParams.totalCount) / paginationParams.countPerPage
  )
  if (pageCount === 0) {
    return null
  }
  const currentPage = Number(paginationParams.currentPage)
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === pageCount
  const category = currentSubcategory || currentGroup
  const baseUrl = {href: category ? `/category` : `/`, as: category ? `/${category.seo}` : `/`}
  const prevUrl = {
    href: {
      pathname: baseUrl.href,
      query: {
        category: category && category.seo,
        page: currentPage - 1,
        type: adsTypes.all
      }
    },
    as: `${baseUrl.as}/${adsTypes.all}/${currentPage - 1}`
  }
  const nextUrl = {
    href: {
      pathname: baseUrl.href,
      query: {
        category: category && category.seo,
        page: currentPage + 1,
        type: adsTypes.all
      }
    },
    as: `${baseUrl.as}/${adsTypes.all}/${currentPage + 1}`
  }
  const pages = []
  for (let i = 1; i <= pageCount; i++) {
    pages.push({
      number: i,
      isCurrent: currentPage === i,
      href: {
        pathname: baseUrl.href,
        query: {
          category: category && category.seo,
          page: i,
          type: adsTypes.all
        }
      },
      as: `${baseUrl.as}/${adsTypes.all}/${i}`
    })
  }

  if (!ads.length) return null

  return (
    <Links
      isFirstPage={isFirstPage}
      isLastPage={isLastPage}
      prevUrl={prevUrl}
      nextUrl={nextUrl}
      pages={pages}
    />
  )
}

const mapStateToProps = state => ({
  ads: getAds(state),
  paginationParams: getPaginationParams(state),
  currentSubcategory: getCurrentSubCategory(state),
  currentGroup: getCurrentGroup(state)
})

Category.propTypes = {
  ads: PropTypes.array.isRequired,
  currentGroup: PropTypes.shape({
    seo: PropTypes.string.isRequired
  }),
  currentSubcategory: PropTypes.shape({
    seo: PropTypes.string.isRequired
  }),
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    countPerPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired
  }).isRequired
}

Category.defaultProps = {
  ads: []
}

export default connect(mapStateToProps)(Category)
