import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Next, Prev, Numbers } from 'SRC/modules/common/components/Pagination'
import { getIsMobileDevice } from 'SRC/modules/common/selectors'
import { MobileLinks } from '../Mobile'

const Links = ({
	isFirstPage,
	isLastPage,
	prevUrl,
	nextUrl,
	pages,
	isMobileDevice
}) => {
	if (isMobileDevice) {
		return (
			<MobileLinks
				isFirstPage={isFirstPage}
				isLastPage={isLastPage}
				prevUrl={prevUrl}
				nextUrl={nextUrl}
				pages={pages}
			/>
		)
	}

	return (
		<div className='oglasi-pagination'>
			<ul id='page-numbers' className='ads-pagination'>
				<Prev isFirstPage={isFirstPage} as={prevUrl.as} href={prevUrl.href} />
				<Numbers pages={pages} />
				<Next isLastPage={isLastPage} as={nextUrl.as} href={nextUrl.href} />
			</ul>
		</div>
	)
}


const mapStateToProps = state => {
	return {
		isMobileDevice: getIsMobileDevice(state)
	}
}

export default connect(mapStateToProps)(Links)
