import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'
import { getAdThumbnailUrl } from 'SRC/modules/ads/shared/functions'

import {
  ItemCardViewDescription,
  ItemImage,
  ItemPlace,
  ItemPrice,
  ItemTime
} from '../../../components/Item'
import { adsListViews } from 'Core/constants'

export const Item = ({ ad }) => {
  const img = getAdThumbnailUrl(ad)

  const hidePriceType = ad.priceTo || ad.priceFrom

  const priceWithDiscountCard = ad.discount ? (ad.price - ad.price * (ad.discount / 100)).toFixed(2) : null

  const adCategory = ad.categorySeo || ad.category

  return (
    <div className={`oglasi-item-list oglasi-item-list-${adCategory}`}>
      <ItemImage
        category={adCategory}
        seo={ad.seo}
        paid={ad.isPaid}
        className='oglasi-item-list-img'
        img={img}
        title={ad.titleCompiled}
      />{'\n'}
      <div className='oglasi-item-list-content'>
        <Link href={`/ad?category=${adCategory}&ad=${ad.seo}`} as={`/${adCategory}/${ad.seo}`}>
          <a className='oglasi-item-heading'>
            <h3>{ad.titleCompiled || ''}</h3>
          </a>
        </Link>
        {'\n'}
        <ItemCardViewDescription
          specs={ad.specifications ? ad.specifications.sort((spec1, spec2) => spec1.weightCatalog - spec2.weightCatalog).slice(0, 3) : []}
          compatibilities={ad.compatibilities ? ad.compatibilities : []}
          service={ad.service ? ad.service : null}
          serviceType={ad.serviceType ? ad.serviceType : null} />
        {'\n'}
        <ItemPrice
          newPrice={ad.price}
          oldPrice={ad.oldPrice}
          priceFrom={ad.priceFrom}
          priceTo={ad.priceTo}
          priceOnRequest={ad.priceOnRequest}
          promotion={ad.akcija}
          priceWithDiscountCard={priceWithDiscountCard}
          type='list'
          priceType={!hidePriceType ? ad.priceType : null}
          typeOfPrice={ad.typeOfPrice}
        />{'\n'}
        <ItemPlace location={ad.city && ad.city.name} style={adsListViews.LIST} />
        <ItemTime time={ad.activated} style={adsListViews.LIST} />
      </div>
    </div>
  )
}

Item.propTypes = {
  ad: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isPaid: PropTypes.bool.isRequired,
    price: PropTypes.number.isRequired,
    oldPrice: PropTypes.number,
    discount: PropTypes.number,
    priceFrom: PropTypes.number,
    priceTo: PropTypes.number,
    priceOnRequest: PropTypes.bool,
    priceType: PropTypes.number.isRequired,
    seo: PropTypes.string.isRequired,
    titleCompiled: PropTypes.string.isRequired,
    specifications: PropTypes.array.isRequired,
    compatibilities: PropTypes.array.isRequired,
    service: PropTypes.object.isRequired,
    serviceType: PropTypes.object.isRequired,
    media: PropTypes.array,
    category: PropTypes.number.isRequired,
    categorySeo: PropTypes.string,
    akcija: PropTypes.number,
    pro: PropTypes.bool,
    brand: PropTypes.object,
    product: PropTypes.object,
    city: PropTypes.object,
    activated: PropTypes.string,
    typeOfPrice: PropTypes.object
  }).isRequired
}
