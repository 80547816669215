import PropTypes from 'prop-types'
import React from 'react'
import { Next } from '../Next'
import { Prev } from '../Prev'
import { Numbers } from '../Numbers'

export const Links = ({
	isFirstPage,
	isLastPage,
	prevUrl,
	nextUrl,
	pages
}) => {
	return (
		<div className='oglasi-pagination oglasi-pagination-mobile'>
			<ul id='page-numbers' className='ads-pagination'>
				<Prev isFirstPage={isFirstPage} as={prevUrl.as} href={prevUrl.href} />
				<Numbers pages={pages} />
				<Next isLastPage={isLastPage} as={nextUrl.as} href={nextUrl.href} />
			</ul>
		</div>
	)
}
