import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'
import { getPages } from '../functions/getPages'

export const Numbers = ({pages, scroll, shallow}) => {
  const pagesWithDots = getPages(pages)
  return (pagesWithDots.map(({number, href, as, isCurrent, isDots}) =>
    <li
      className={`ads-pagination__item ${isCurrent ? 'ads-pagination__item_current active' : ''}`}
      key={number}
      id={number}
    >
      {
        isDots ? <a href='javascript:void(0)'>...</a>
          : isCurrent
            ? <span className={'ads-pagination_item-span'}>{number}</span>
            : <Link href={href} as={as} scroll={scroll} shallow={shallow}>
              <a className={'ads-pagination__item-link'}>{number}</a>
            </Link>
      }
    </li>
  ))
}

Numbers.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.any.isRequired,
    as: PropTypes.any.isRequired,
    number: PropTypes.number.isRequired,
    isCurrent: PropTypes.bool.isRequired
  })).isRequired,
  scroll: PropTypes.bool.isRequired,
  shallow: PropTypes.bool.isRequired
}

Numbers.defaultProps = {
  scroll: true,
  shallow: false
}
