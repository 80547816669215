import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'

export const Next = ({ isLastPage, href, as }) => (
  !isLastPage
    ? (
      <li className={`ads-pagination__item`}>
        <Link href={href} as={as}>
          <a className={'ads-pagination__item-link'}>&gt;&gt;</a>
        </Link>
      </li>
    ) : ''
)

Next.propTypes = {
  href: PropTypes.any.isRequired,
  as: PropTypes.any.isRequired,
  isLastPage: PropTypes.bool
}

Next.defaultProps = {
  isLastPage: false
}
