import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

export const Next = ({ isLastPage, href, as, scroll, shallow }) => {
  const { t } = useTranslation('common')

  return !isLastPage ? (
    <li className={`ads-pagination__item`}>
      <Link href={href} as={as} scroll={scroll} shallow={shallow}>
        <a className={'ads-pagination__item-link'}>{t('sledeća')}</a>
      </Link>
    </li>
  ) : ''
}

Next.propTypes = {
  href: PropTypes.any.isRequired,
  as: PropTypes.any.isRequired,
  isLastPage: PropTypes.bool,
  scroll: PropTypes.bool.isRequired,
  shallow: PropTypes.bool.isRequired
}

Next.defaultProps = {
  isLastPage: false,
  scroll: true,
  shallow: false
}
