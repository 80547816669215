import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'

export const Prev = ({ isFirstPage, href, as }) => (
  !isFirstPage
    ? (
      <li className={`ads-pagination__item`}>
        <Link href={href} as={as}>
          <a className={'ads-pagination__item-link'}>&lt;&lt;</a>
        </Link>
      </li>
    ) : ''
)

Prev.propTypes = {
  href: PropTypes.any.isRequired,
  as: PropTypes.any.isRequired,
  isFirstPage: PropTypes.bool
}

Prev.defaultProps = {
  isFirstPage: false
}
