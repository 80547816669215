import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getPaginationParams } from '../../selectors'
import { getProfileAdsQuery } from 'SRC/modules/users/Profile/selectors'
import { profileMenu } from 'SRC/modules/users/Profile/constants'
import { Numbers, Next, Prev, Links } from 'SRC/modules/common/components/Pagination'

export class ProfileAds extends React.PureComponent {
  getPageCount = () => Math.ceil((this.props.paginationParams.totalCount) / this.props.paginationParams.countPerPage)

  getPages = (pageCount = 1) => {
    const pages = []
    const baseUrl = this.getBaseUrl()
    const current = Number(this.props.paginationParams.currentPage)
    for (let i = 1; i <= pageCount; i++) {
      pages.push({
        number: i,
        isCurrent: i === current,
        href: {pathname: baseUrl.href, query: {page: profileMenu.ads.code, ...this.props.queryParams, pageNumber: i}},
        as: {pathname: baseUrl.as, query: {...this.props.queryParams, pageNumber: i}}
      })
    }
    return pages
  }

  getBaseUrl = () => ({href: `/profile`, as: `/profil/${profileMenu.ads.code}`})

  getPrevUrl = () => ({
    href: {
      pathname: this.getBaseUrl().href,
      query: {
        page: profileMenu.ads.code,
        ...this.props.queryParams,
        pageNumber: Number(this.props.paginationParams.currentPage) - 1
      }
    },
    as: {
      pathname: this.getBaseUrl().as,
      query: {...this.props.queryParams, pageNumber: Number(this.props.paginationParams.currentPage) - 1}
    }
  })

  getNextUrl = () => ({
    href: {
      pathname: this.getBaseUrl().href,
      query: {
        page: profileMenu.ads.code,
        ...this.props.queryParams,
        pageNumber: Number(this.props.paginationParams.currentPage) + 1
      }
    },
    as: {
      pathname: this.getBaseUrl().as,
      query: {...this.props.queryParams, pageNumber: Number(this.props.paginationParams.currentPage) + 1}
    }
  })

  render () {
    const pageCount = this.getPageCount()
    const pages = this.getPages(pageCount)
    const currentPage = Number(this.props.paginationParams.currentPage)
    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === pageCount
    const prevUrl = this.getPrevUrl()
    const nextUrl = this.getNextUrl()

    if (pageCount <= 0) return null

    return (
      <Links
        isFirstPage={isFirstPage}
        isLastPage={isLastPage}
        prevUrl={prevUrl}
        nextUrl={nextUrl}
        pages={pages}
      />
    )

    return pageCount > 0
      ? <div className='oglasi-pagination'>
        <ul id='page-numbers' className='ads-pagination'>
          <Prev isFirstPage={isFirstPage} href={prevUrl.href} as={prevUrl.as} />
          <Numbers pages={pages} />
          <Next isLastPage={isLastPage} href={nextUrl.href} as={nextUrl.as} />
        </ul>
      </div>
      : null
  }
}

const mapStateToProps = state => ({
  paginationParams: getPaginationParams(state),
  queryParams: getProfileAdsQuery(state)
})

ProfileAds.propTypes = {
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    countPerPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired
  }).isRequired,
  queryParams: PropTypes.shape()
}

export default connect(mapStateToProps)(ProfileAds)
