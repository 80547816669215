import PropTypes from 'prop-types'
import React from 'react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

export const Prev = ({ isFirstPage, href, as, scroll, shallow }) => {
  const { t } = useTranslation('common')

  return !isFirstPage ? (
    <li className={`ads-pagination__item`}>
      <Link href={href} as={as} scroll={scroll} shallow={shallow}>
        <a className={'ads-pagination__item-link'}>{t('prethodna')}</a>
      </Link>
    </li>
  ) : ''
}

Prev.propTypes = {
  isFirstPage: PropTypes.bool,
  as: PropTypes.any.isRequired,
  href: PropTypes.any.isRequired,
  scroll: PropTypes.bool.isRequired,
  shallow: PropTypes.bool.isRequired
}

Prev.defaultProps = {
  isFirstPage: false,
  scroll: true,
  shallow: false
}
