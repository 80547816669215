import React from 'react'
import PropTypes from 'prop-types'
import { adsListViews } from 'Core/constants'
import { Item } from '../Item'
import { Preloader } from 'SRC/ui/Preloader'

export const List = React.forwardRef((props, ref) => (
  <div
    className={`oglasi-content-list ${props.ads.length ? '' : 'oglasi-content-list_no-ads'}`}
    style={{ display: props.style === adsListViews.LIST ? 'block' : 'none' }}
    ref={ref}
  >
    {props.ads.length && props.style === adsListViews.LIST
      ? props.ads.map(item => [
        !item.banner ? <Item ad={item} key={item.id} /> : null, '\n'
      ]) : props.isLoading
        ? <Preloader />
        : (
          <div className='oglasi-content-text__no-ads-text'>
            {props.noAdsMessage}
          </div>
        )
    }
  </div>
))

List.displayName = 'CardView'

List.propTypes = {
  ads: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.oneOf([
    adsListViews.LIST,
    adsListViews.TABLE
  ]),
  noAdsMessage: PropTypes.string,
  isLoading: PropTypes.bool
}

List.defaultProps = {
  ads: []
}
